.table {
  width: 100%;
  margin-bottom: 20px;
}

.table datatable-header-cell,
.table datatable-body-cell {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}

.table datatable-header-cell {
  font-weight: bold;
}

.table datatable-header datatable-header-cell {
  vertical-align: bottom;
}

.table caption + datatable-header datatable-header-cell,
.table caption + datatable-header datatable-row-wrapper:first-child datatable-body-cell,
.table colgroup + datatable-header datatable-header-cell,
.table colgroup + datatable-header datatable-row-wrapper:first-child datatable-body-cell,
.table datatable-header:first-child datatable-header-cell,
.table datatable-header:first-child datatable-row-wrapper:first-child datatable-body-cell {
  border-top: 0;
}

.table datatable-body + datatable-body {
  border-top: 2px solid #dddddd;
}

.table .table {
  background-color: #ffffff;
}

.table-condensed datatable-header-cell,
.table-condensed datatable-body-cell {
  padding: 4px 5px;
}

.table-bordered {
  border: 1px solid #dddddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.table-bordered datatable-header-cell,
.table-bordered datatable-body-cell,
.table-bordered datatable-footer {
  border-left: 1px solid #dddddd;
}

.table-bordered caption + datatable-header datatable-header-cell,
.table-bordered caption + datatable-body datatable-row-wrapper:first-child datatable-body-cell,
.table-bordered colgroup + datatable-header datatable-header-cell,
.table-bordered colgroup + datatable-body datatable-row-wrapper:first-child datatable-body-cell,
.table-bordered datatable-header:first-child datatable-header-cell,
.table-bordered datatable-body:first-child datatable-row-wrapper:first-child datatable-body-cell {
  border-top: 0;
}

.table-bordered datatable-header:first-child > datatable-header-cell:first-child,
.table-bordered datatable-body:first-child datatable-row-wrapper:first-child > datatable-body-cell:first-child {
   -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered datatable-header:first-child > datatable-header-cell:last-child,
.table-bordered datatable-body:first-child datatable-row-wrapper:first-child > datatable-body-cell:last-child {
   -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-bordered datatable-header:last-child tr:last-child > datatable-header-cell:first-child,
.table-bordered datatable-body:last-child datatable-row-wrapper:last-child > datatable-body-cell:first-child,
 .table-bordered tfoot:last-child datatable-row-wrapper:last-child > datatable-body-cell:first-child,
.table-bordered tfoot:last-child tr:last-child > datatable-header-cell:first-child {
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
}

.table-bordered datatable-header:last-child tr:last-child > datatable-header-cell:last-child,
.table-bordered datatable-body:last-child datatable-row-wrapper:last-child > datatable-body-cell:last-child,
.table-bordered tfoot:last-child datatable-row-wrapper:last-child > datatable-body-cell:last-child,
.table-bordered tfoot:last-child tr:last-child > datatable-header-cell:last-child {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
}

.table-bordered tfoot + datatable-body:last-child datatable-row-wrapper:last-child datatable-body-cell:first-child {
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
}

.table-bordered tfoot + datatable-body:last-child datatable-row-wrapper:last-child datatable-body-cell:last-child {
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
}

.table-bordered caption + datatable-header datatable-header-cell:first-child,
.table-bordered caption + datatable-body datatable-row-wrapper:first-child datatable-body-cell:first-child,
.table-bordered colgroup + datatable-header datatable-header-cell:first-child,
.table-bordered colgroup + datatable-body datatable-row-wrapper:first-child datatable-body-cell:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered caption + datatable-header datatable-header-cell:last-child,
.table-bordered caption + datatable-body datatable-row-wrapper:first-child datatable-body-cell:last-child,
.table-bordered colgroup + datatable-header datatable-header-cell:last-child,
.table-bordered colgroup + datatable-body datatable-row-wrapper:first-child datatable-body-cell:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-bordered datatable-body datatable-row-wrapper:last-child {
  border-bottom: 1px solid #dddddd;
}

// Fixup to merge footer border.  Magic number
.table-bordered datatable-body {
  margin-bottom: -4px;
}
