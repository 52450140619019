
/******** Grid Global ********/
.nglabel {
    display: block;
    float: left;
    font-weight: bold;
    padding-right: 5px;
}
.ngNoSelect{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/******** Grid ********/

.ngGrid{
    background-color: rgb(253, 253, 253);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/******** Header ********/

.ngGroupPanel{
    background-color: rgb(234, 234, 234);
    overflow: hidden;
	border-bottom: 1px solid rgb(212,212,212);
}

.ngGroupPanelDescription{
	margin-top: 5px;
	margin-left: 5px;
}

.ngGroupList {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.ngGroupItem {
	float: left; 
}

.ngGroupElement {
	float: left;
	height: 100%; 
	width: 100%;
}

.ngGroupName {
    background-color: rgb(247,247,247);
    border: 1px solid rgb(212,212,212);
    padding: 3px 10px;
    float: left;
    margin-left: 0;
    margin-top: 2px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    font-weight: bold;
}

.ngGroupItem:first-child{
	margin-left: 2px;
}

.ngRemoveGroup {
    width: 5px;
    float: right;
    -moz-opacity: 0.4;
    opacity: 0.4;
    margin-top: -1px;
    margin-left: 5px;
}
.ngRemoveGroup:hover {
    color: black;
    text-decoration: none;
    cursor: pointer;
    -moz-opacity: 0.7;
    opacity: 0.7;
}
.ngGroupArrow {
	width: 0; 
	height: 0; 
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 6px solid black;
	margin-top: 10px;
	margin-left: 5px;
	margin-right: 5px;
	float: right;
}

.ngTopPanel {
    position: relative;	
	z-index:5;
    background-color: rgb(234, 234, 234);
	border-bottom: 1px solid rgb(212,212,212);
}
.ngHeaderContainer {
    position: relative;
    overflow: hidden;
    font-weight: bold;
}

.ngHeaderScroller {
	position:absolute;
}
.ngHeaderSortColumn{
	position:absolute;
    overflow: hidden;
}

.ngHeaderCell{
	border-left: 1px solid rgb(212,212,212);
    position: absolute;
    top: 0; 
    bottom: 0; 
}

.ngHeaderCell:first-child{
    border-left: 0;
}

.ngHeaderCell:last-child{
	border-right: 1px solid rgb(212,212,212);
}

.ngSortButtonUp {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
	border-color: gray transparent;
	border-style: solid;
	border-width: 0 5px 5px 5px;
	height: 0;
	width: 0;
}
.ngSortButtonDown {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
	border-color: gray transparent;
	border-style: solid;
	border-width: 5px 5px 0 5px;
    height: 0;
	width: 0;
}
.ngHeaderGrip { 
    cursor: col-resize; 
    width: 10px; 
	right: -5px;
    top: 0; 
    height: 100%; 
    position: absolute; 
    z-index: 5; 
}
.ngHeaderText {
    padding: 5px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}

/******** Viewport ********/
.ngViewport{
	overflow: auto;
    min-height: 20px;
}

.ngCanvas{
    position: relative;
}

/******** Rows ********/
.ngRow {
    position: absolute;
    border-bottom: 1px solid rgb(229, 229, 229);
}
.ngRow.even {
    background-color: rgb(243, 243, 243);
}
.ngRow.odd {
    background-color: rgb(253, 253, 253);
}
.ngRow.selected {
    background-color: rgb(189, 208, 203);
}
.ngRow.canSelect {
    cursor: pointer;
}

/******** Cells ********/

.ngCell {
    overflow: hidden; 
    position: absolute;
	border-left: 1px solid rgb(212,212,212);
    top: 0; 
    bottom: 0; 
}

.ngCell:first-child{
	border-left: 0;
}

.ngCell:last-child {
    border-right: 1px solid rgb(212,212,212);
}

.ngCellText {
    padding: 5px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}
.ngSelectionHeader {
	position: absolute;
    top: 11px; 
    left: 6px;
}
input[type="checkbox"] {
	margin: 0;
	padding: 0;
}
input {
	vertical-align:top;
}
.ngSelectionCell{
	margin-top: 9px;
	margin-left: 6px;
}
.ngNoSort {
    cursor:default;
}

/******** Footer ********/
.ngFooterPanel{
    background-color: rgb(234, 234, 234);
    padding: 0;
	border-top: 1px solid rgb(212,212,212);
	position: relative;
}
.ngTotalSelectContainer {
    float: left;
    margin: 5px;
    margin-top: 7px;
}
.ngFooterSelectedItems {
	padding: 2px;
}
.ngFooterTotalItems {
	padding: 2px;
}
.ngFooterTotalItems.ngnoMultiSelect {
    padding: 0 !important;
}

/* Aggregates */
.ngAggHeader {
    position: absolute;
    border: none;
}
.ngAggregate {
    position: absolute;
    background-color: rgb(179, 191, 188);
    border-bottom: 1px solid beige;
    overflow: hidden;
    top: 0;
    bottom: 0;
    right: -1px;
	left: 0;
}
.ngAggregateText {
    position: absolute;
    left: 27px;
	top: 5px;
    line-height: 20px;
    white-space:nowrap;
}
.ngAggArrowExpanded {
    position: absolute;
    left: 8px;
    bottom: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 9px 9px;
    border-color: transparent transparent #000000 transparent;
}
.ngAggArrowCollapsed {
    position: absolute;
    left: 8px;
    bottom: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 8.7px;
    border-color: transparent transparent transparent #000000;
}

.ngHeaderButton {
    position: absolute;    
    right: 2px;
    top: 8px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    z-index: 5;
    background-color: rgb(179, 191, 188);
    cursor: pointer;
    /* width and height can be anything, as long as they're equal */
}
.ngHeaderButtonArrow {
    position: absolute;
    top: 4px;
    left: 3px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6.5px 4.5px 0 4.5px;
    border-color: #000 transparent transparent transparent;
    /* width and height can be anything, as long as they're equal */
}
.ngColMenu {
    right: 2px;
    padding: 5px;
    top: 25px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-color: #BDD0CB;
    position: absolute;
    border: 2px solid rgb(212,212,212);
    z-index: 5;
}
.ngMenuText {
    position: relative;
    top: 2px;
    left: 2px;
}
.ngColList {
    list-style-type: none;
}

.ngColListItem {
    position: relative;
    right: 17px;
    top: 2px;
    white-space:nowrap;
}
.ngColListCheckbox {
    position: relative;
    right: 3px;
    top: 4px;
}

/********Paging Styles **********/

.ngPagerButton{
	height: 25px;
	min-width: 26px;
}

.ngPagerFirstTriangle{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 8.7px 5px 0;
	border-color: transparent #000000 transparent transparent;
	margin-left: 2px;
}

.ngPagerFirstBar{
	width: 10px;
	border-left: 2px solid black;
	margin-top: -6px;
	height: 12px;
	margin-left: -3px;
}	

.ngPagerLastTriangle{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 0 5px 8.7px;
	border-color: transparent transparent transparent #000000;
	margin-left: -1px;
}

.ngPagerLastBar{
	width: 10px;
	border-left: 2px solid black;
	margin-top: -6px;
	height: 12px;
	margin-left: 1px;
}	

.ngPagerPrevTriangle{
	margin-left: 0;
}	

.ngPagerNextTriangle{
	margin-left: 1px;
}
.ngGroupIcon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAEFJREFUKFNjoAhISkr+h2J5JDZODNXGwGBsbPwfhIGAA8bGh6HaGBiAGhxAGJmND4M1gQCSM0adCsVQbcPcqQwMALWDGyDvWPefAAAAAElFTkSuQmCC);
	background-repeat:no-repeat;
    height: 15px;
    width: 15px;
    position: absolute;
    right: -2px;
    top: 2px;
}

.ngGroupedByIcon {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAElJREFUKFNjoAhISkr+R8LyaHwMDNXGwGBsbPwfhoGAA5mPDUO1oWpE52PDYE0gALTFAYbR+dgwWBMIoPlh1I9ADNU2NPzIwAAAFQYI9E4OLvEAAAAASUVORK5CYII=);
	background-repeat:no-repeat;
    height: 15px;
    width: 15px;
    position: absolute;
    right: -2px;
    top: 2px;
}

.ngGroupingNumber {
    position: absolute;
    right: -10px;
    top: -2px;
}
